import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const ImageWrapper = styled.div`
  margin-top: 16px;

  ${breakpoint.medium`
    margin-top: 60px;
  `}

  .image {
    width: 95%;
    max-width: 1300px;
    display: block;

    &:nth-child(odd) {
      margin-left: auto;
    }

    ${breakpoint.medium`
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const IllustrationSystem = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: {
          eq: "case-studies/nubi/illustration-system-image-1.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: {
          eq: "case-studies/nubi/illustration-system-image-2.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageThree: file(
        relativePath: {
          eq: "case-studies/nubi/illustration-system-image-3.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>Supporting communication with an Illustration System</Trans>
          </p>
          <p>
            <Trans>
              To achieve a friendlier look & feel and enhance both the website
              and the web app from a storytelling perspective, we designed
              custom professional illustrations. We also developed an
              illustration system with the foundations needed to create new
              assets in the future.
            </Trans>
          </p>
        </div>
      </div>
      <ImageWrapper>
        <Img
          className="image"
          fluid={data.imageOne.childImageSharp.fluid}
          alt="Illustration System"
        />
        <Img
          className="image"
          fluid={data.imageTwo.childImageSharp.fluid}
          alt="Illustration System"
        />
        <Img
          className="image"
          fluid={data.imageThree.childImageSharp.fluid}
          alt="Illustration System"
        />
      </ImageWrapper>
    </Section>
  )
}

export default IllustrationSystem