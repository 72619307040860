import React from "react"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"

const Challange = () => (
  <Section>
    <div className="container">
      <div className="section__title">
        <p>
          <Trans>The challenge</Trans>
        </p>
      </div>

      <div className="section__content">
        <h3>
          <Trans>
            How can we improve the usability of an existent product in a
            frictionless way?
          </Trans>
        </h3>
        <br />
        <p>
          <Trans>
            Nubi developed an alliance with PayPal in 2017, becoming the only
            platform in Argentina that lets you connect your bank account with
            the most important international payment system in the world.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            Nubi was continuously growing, improving their response time,
            optimizing their operations and scaling to handle a huge volume of
            transactions. At the same time, their product interface was
            outdated, full of UX problems and visually unappealing.
          </Trans>
        </p>
      </div>
    </div>
  </Section>
)

export default Challange