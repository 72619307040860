import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const ImageWrapper = styled.div`
  display: block;
  margin-top: 32px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}

  > div {
    width: 100%;
  }
`

const Solution = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: {
          eq: "case-studies/nubi/the-solution-image--desktop.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section>
      <div className="container">
        <div className="section__title">
          <p>
            <Trans>The solution</Trans>
          </p>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Communicating and designing a more user-friendly product
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              Transforming the platform’s user interface and experience (UI/UX)
              would also mean an opportunity to change their communication and
              marketing website. There was a real need to, not only align the
              visual aspect of both channels, but also to communicate the added
              value of their new product and, thus, increase their user base.
            </Trans>
          </p>
        </div>
      </div>

      <ImageWrapper>
        <Img
          fluid={data.desktop.childImageSharp.fluid}
          style={{
            minHeight: "296px",
            objectFit: "fill",
            objectPosition: "30% center",
          }}
          alt="The solution"
        />
      </ImageWrapper>
    </Section>
  )
}

export default Solution