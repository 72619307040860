import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  padding-bottom: 0 !important;
  background-color: ${colors.cream};

  .image {
    width: 100%;
    margin-top: 32px;

    ${breakpoint.medium`
      margin-top: 80px;
    `}
  }
`

const NewWebsite = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/nubi/new-website-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledSection>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>New Marketing Website</Trans>
          </p>
          <p>
            <Trans>
              The product was changing, so we had to re-think the way it was
              being communicated to users. We designed a new marketing site that
              was up to par with the major improvements we’d made in the updated
              version of the product. We also applied a modular structure, so
              that Nubi’s internal team had the flexibility to switch, add or
              remove different content blocks depending on their future needs.
            </Trans>
          </p>
        </div>

        <Img
          className="image"
          fluid={data.file.childImageSharp.fluid}
          alt="New Marketing Website"
        />
      </div>
    </StyledSection>
  )
}

export default NewWebsite