import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Section from "components/case-study-section/"
import Carousel from "components/case-study-carousel/"
import Quote from "components/case-study-quote"

const StyledSection = styled(Section)`
  padding-top: 56px !important;
  background-color: ${colors.cream};

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}
`

const StyledCarousel = styled(Carousel)`
  width: 100%;
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}

  .slide {
    max-width: 928px;
  }
`

const StyledQuote = styled(Quote)`
  margin-top: 56px;

  ${breakpoint.medium`
    margin-top: 120px;
  `}
`

const Results = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      franciscoPhoto: file(
        relativePath: { eq: "case-studies/nubi/francisco-ferreiro.jpg" }
      ) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      carouselImageOne: file(
        relativePath: { eq: "case-studies/nubi/the-results-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/nubi/the-results-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/nubi/the-results-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/nubi/the-results-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFive: file(
        relativePath: { eq: "case-studies/nubi/the-results-image-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carouselImageSix: file(
        relativePath: { eq: "case-studies/nubi/the-results-image-6.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carouselImageSeven: file(
        relativePath: { eq: "case-studies/nubi/the-results-image-7.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>Making it easy for freelancers</Trans>
          </h3>
          <br />
          <p>
            <Trans>
              The Nubi team gave us access to valuable user feedback and
              potential improvements, which allowed us to empathize with their
              users and provide solutions for the biggest pain points.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              We redesigned the whole experience for Nubi, prioritizing the
              user's needs. We also created an onboarding process to ensure that
              existent users had a frictionless transition to the new version of
              the app and identified the added value from the getgo.
            </Trans>
          </p>
          <br />
          <p>
            <b>
              <Trans>The result:</Trans>
            </b>
            <Trans>An effortless transactional experience.</Trans>
          </p>
        </div>
      </div>
      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Img fluid={data.carouselImageOne.childImageSharp.fluid} alt="Nubi" />
        </div>
        <div data-slide className="slide">
          <Img fluid={data.carouselImageTwo.childImageSharp.fluid} alt="Nubi" />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="Nubi"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="Nubi"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFive.childImageSharp.fluid}
            alt="Nubi"
          />
        </div>
        <div data-slide className="slide">
          <Img fluid={data.carouselImageSix.childImageSharp.fluid} alt="Nubi" />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageSeven.childImageSharp.fluid}
            alt="Nubi"
          />
        </div>
      </StyledCarousel>

      <div className="container">
        <StyledQuote
          quote={t(
            "“It was like working with a friend. We understood each other very quickly, and their work's quality was excellent.”"
          )}
          author="Francisco Ferreiro"
          position={t("Nubi's Design Lead")}
          image={data.franciscoPhoto.childImageSharp.fixed}
          backgroundColor={colors.indiblue}
          color={colors.white}
        />
      </div>
    </StyledSection>
  )
}

export default Results