import React from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Components
import CaseStudyHero from "components/case-study-hero/"
import FeaturedImage from "./featured-image"

const Hero = () => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <CaseStudyHero
        title={t(
          "Validating telemedicine with a top health insurance provider"
        )}
        details={["OSDE", "Buenos Aires, Argentina", "2019"]}
        tags={["Design Sprint"]}
        backgroundColor={colors.supernova}
        color={colors.midnight}
        bulletColor={colors.indiblue}
      />
      <FeaturedImage />
    </React.Fragment>
  )
}

export default Hero
